const { version } = require('../package.json');
export { version };

/**
 * 预加载各开发包的资源入口地址
 */
const entrypoints = fetch(process.env.PUBLIC_URL + '/entrypoints.json', {
  cache: 'no-cache'  // 避免强缓存，改用协商缓存
}).then(async response => {
  if (response.ok) {
    return await response.json();
  }
});

/**
 * JS和CSS的加载器
 * @param {string} entrys URL地址的数组
 * @returns 无
 */
export function load(entrys) {
  return Promise.all(entrys.map(entry => new Promise((resolve, reject) => {
    if (entry.endsWith('.js')) {
      let s = document.createElement('script');
      s.src = process.env.PUBLIC_URL + '/' + entry;
      s.onload = () => resolve();
      s.onerror = () => reject();
      let head = document.getElementsByTagName('head')[0];
      head.appendChild(s);
    }
    if (entry.endsWith('.css')) {
      let s = document.createElement('link');
      s.rel = "stylesheet";
      s.href = process.env.PUBLIC_URL + '/' + entry;
      s.onload = () => resolve();
      s.onerror = () => reject();
      let head = document.getElementsByTagName('head')[0];
      head.appendChild(s);
    }
  })));
}

/**
 * 获取Base包内的App入口对象
 * @returns App对象
 */
export async function getApp() {
  if (!window.Base) {
    const { Base } = await entrypoints;
    if (Base) {
      await load(Base);
    } else {
      return null;
    }
  }

  return window.Base.App;
}

/**
 * 获取开发包的导出资源
 * @param {string} name 包名
 * @returns 包对象
 */
export async function getPackage(name) {
  if (!window[name]) {
    const entrys = await entrypoints;
    if (entrys[name]) {
      await load(entrys[name]);
    } else {
      return null;
    }
  }

  return window[name];
}

/**
 * 获取开发包内的页面对象
 * @param {string} fullname 包名:页面描述符
 * @returns 页面对象
 */
export async function getPage(fullname) {
  const [pack_name, page_name] = fullname.split(':');
  const pack = await getPackage(pack_name);
  return pack ? pack.pages.get(page_name) : null;
}

const root = window.ReactDOM.createRoot(document.getElementById('root'));
getApp().then(App => root.render(App ? window.React.createElement(App) : null));